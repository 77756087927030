var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.title } },
    [
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_general")) + " ")]),
          _c("FormHeader", { ref: "formHeader" })
        ],
        1
      ),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_unit_code")) + " ")]),
          _c("FormUnitCode")
        ],
        1
      ),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_payment")) + " ")]),
          _c("FormPayment")
        ],
        1
      ),
      _c("a-divider"),
      _c("DisplayTotal"),
      _c("a-divider"),
      _c(
        "div",
        { staticClass: "mt-2 text-right" },
        [
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.handleBack } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
              ]),
              _vm.$can("update", "leasing") && _vm.store.form.cancellable
                ? [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: _vm.$t("pop_confirmation"),
                          "ok-text": _vm.$t("lbl_yes"),
                          "cancel-text": _vm.$t("lbl_no")
                        },
                        on: { confirm: _vm.handleCancel }
                      },
                      [
                        _c("a-button", { attrs: { type: "danger" } }, [
                          _vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")
                        ])
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.$can("create", "leasing") && _vm.isCreate
                ? _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.loading.saveDraft },
                      on: { click: _vm.handleSaveDraft }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save_as_draft")) + " ")]
                  )
                : _vm._e(),
              _vm.$can("update", "leasing") && _vm.isDraft
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading.update },
                      on: {
                        click: function($event) {
                          return _vm.validateForm("update")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_update")) + " ")]
                  )
                : _vm._e(),
              _vm.$can("update", "leasing") && (_vm.isCreate || _vm.isDraft)
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading.submit },
                      on: {
                        click: function($event) {
                          return _vm.validateForm("submit")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }