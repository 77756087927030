




































































import {
  DisplayTotal,
  FormHeader,
  FormPayment,
  FormUnitCode,
} from "@/components/Leasing";
import { useLeasing } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import { LeasingCreateRequestDto } from "@/models/interface/leasing";
import { FormValue, State } from "@/store/leasing.store";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";

const NEW_DOCUMENT_FLAG = "-";

@Component({
  components: {
    FormHeader,
    DisplayTotal,
    FormUnitCode,
    FormPayment,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.leasingStore,
    }),
    ...mapGetters({
      isDraft: "leasingStore/isDraft",
    }),
  },
  methods: {
    ...mapActions({
      getDefaultCurrency: "leasingStore/getDefaultCurrency",
      fetchDetail: "leasingStore/fetchDetail",
    }),
  },
})
export default class FormPage extends Mixins(MNotificationVue) {
  store!: State;
  isDraft!: boolean;
  getDefaultCurrency!: () => void;
  fetchDetail!: (leasingId: string) => Promise<void>;

  @Ref("formHeader")
  formHeader!: any;

  @Prop()
  id!: string; // leasing secureId

  loading = {
    cancel: false,
    submit: false,
    update: false,
    saveDraft: false,
  };

  mounted(): void {
    this.getDefaultCurrency();
  }

  get isCreate(): boolean {
    const mode = this.$route.meta?.mode || "";
    return mode === Mode.CREATE && !this.id;
  }

  get isEdit(): boolean {
    const mode = this.$route.meta?.mode || "";
    return mode === Mode.EDIT && !!this.id;
  }

  get title(): string {
    const lblEdit = this.$t("lbl_edit_x", {
      x: this.$t("lbl_leasing"),
    }).toString();
    const lblCreate = this.$t("lbl_create_x", {
      x: this.$t("lbl_leasing"),
    }).toString();

    return this.isCreate ? lblCreate : lblEdit;
  }

  handleBack(): void {
    this.$router.push({ name: "account-payables.leasing" });
  }

  validateForm(action: "update" | "submit"): void {
    const formModel: FormModel = this.formHeader.formModel;

    formModel.validate(valid => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      if (action === "update") {
        this.handleUpdate(this.id, this.store.form);
      } else if (action === "submit" && !!this.id) {
        this.handleSubmit(this.id, this.store.form);
      } else if (action === "submit") {
        this.handleSubmit(NEW_DOCUMENT_FLAG, this.store.form);
      }
    });
  }

  handleSubmit(leasingId: string, form: FormValue): void {
    const { submit, mapFormToCreateDto } = useLeasing();
    const req: LeasingCreateRequestDto = mapFormToCreateDto(form);
    this.loading.submit = true;
    submit(leasingId, req)
      .then(({ leasingNumber }) => {
        this.showNotifSuccess("notif_submit_success", {
          documentNumber: leasingNumber,
        });
        this.fetchDetail(leasingId);
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  handleSaveDraft(): void {
    const { create, mapFormToCreateDto } = useLeasing();
    const req = mapFormToCreateDto(this.store.form);
    this.loading.saveDraft = true;
    create(req)
      .then(({ id }) => {
        this.showNotifSuccess("notif_create_success");
        this.$router.push({
          name: "account-payables.leasing.edit",
          params: { id },
        });
      })
      .finally(() => {
        this.loading.saveDraft = false;
      });
  }

  handleUpdate(leasingId: string, form: FormValue): void {
    const { update, mapFormToCreateDto } = useLeasing();
    const req: LeasingCreateRequestDto = mapFormToCreateDto(form);
    this.loading.update = true;
    update(leasingId, req)
      .then(() => {
        this.showNotifSuccess("notif_update_success", {
          documentNumber: form.leasingNumber,
        });
        this.fetchDetail(leasingId);
      })
      .finally(() => {
        this.loading.update = false;
      });
  }

  handleCancel(): void {
    const { cancelLeasing } = useLeasing();
    this.loading.cancel = true;
    cancelLeasing(this.id)
      .then(() => {
        this.showNotifSuccess("notif_cancel_success", {
          documentNumber: this.store.form.leasingNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading.cancel = false;
      });
  }
}
